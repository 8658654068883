@import "variables";
@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
@import "~react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
@import "~react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
@import "~react-datepicker/dist/react-datepicker.css";
@import "~swiper/css/swiper.min.css";
@import "~viewerjs/dist/viewer.min.css";

.docs-pictures {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.docs-pictures > li {
  border: 1px solid transparent;
  float: left;
  height: 200px;
  margin: 0 -1px -1px 0;
  overflow: hidden;
  width: 200px;
  line-height: 200px;
}

.docs-pictures > li > img {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  color: $lightGrey;
  text-shadow: 1px 1px $darkgrey;
}

.swiper-pagination-bullet-active {
  background-color: $heatonsOrange !important;
}

.swiper-pagination-bullet {
  background-color: $lightGrey;
}

//Main Variables
$orange: rgba(231, 121, 43, 1);
$heatonsOrange: $orange;
$lightGrey: #e7e7e9;
$grey: #cccccc;
$darkgrey: #4d4d4d;
$headerSize: 100px;
$fontColor: #4d4d4d;
$heatonsNavy: #2f4958;

//Cart Variables
$nextDayMessage: 'Next day delivery not available';
$totalCartTitle: 'Order Summary';
$swapDescriptionPrefix: 'Alternative:';
$swapGreen: #2bb918;